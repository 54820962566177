import {
  GetBusinessResponse,
  Property,
} from '@wix/ambassador-services-catalog-server/types';
import { TimezoneType } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getUoULocale } from '../utils/locale/locale';
import { BusinessInfo } from '../../legacy/types';
import { isAnywhereFlow } from '../utils/anywhere/anywhere';
import {
  getLanguage,
  getLocale,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { ViewMode } from '../types/types';

export type ServiceListContext = {
  isMultiServiceAppointmentEnabled: boolean;
  isPricingPlanInstalled: boolean;
  isAnywhereFlow: boolean;
  businessInfo: BusinessInfo;
  viewMode: ViewMode;
};

type CreateServiceListContextParams = {
  getBusinessResponse: GetBusinessResponse;
  flowAPI: ControllerFlowAPI;
  isPricingPlanInstalled: boolean;
  viewMode: ViewMode;
};

export const createServiceListContext = async ({
  flowAPI,
  getBusinessResponse,
  isPricingPlanInstalled,
  viewMode,
}: CreateServiceListContextParams): Promise<ServiceListContext> => {
  const {
    environment: { isPreview },
    controllerConfig: { wixCodeApi },
  } = flowAPI;
  const businessInfo =
    mapGetBusinessResponseToBusinessInfo(getBusinessResponse);

  businessInfo.language = getLanguage(wixCodeApi);
  businessInfo.regionalSettings = getLocale(wixCodeApi);

  const isMultiServiceAppointmentEnabled =
    getBusinessResponse.businessProperties?.customProperties?.find(
      (businessProperty) =>
        businessProperty.propertyName === 'isMultiServicesAppointmentsEnable',
    )?.value === 'true';

  const isAnywhereFlowInd = await isAnywhereFlow(wixCodeApi, isPreview);

  return {
    isMultiServiceAppointmentEnabled,
    isPricingPlanInstalled,
    isAnywhereFlow: isAnywhereFlowInd,
    businessInfo,
    viewMode,
  };
};

const mapGetBusinessResponseToBusinessInfo = (
  getBusinessResponse: GetBusinessResponse,
): BusinessInfo => {
  const { info } = getBusinessResponse;
  const { name, language, timeZone, locale } = info!;

  return {
    language: getUoULocale(language || ''),
    name: name || '',
    regionalSettings: locale!,
    timeZone: timeZone!,
    timezoneProperties: {
      defaultTimezone: getDefaultTimezoneType(
        getBusinessResponse.businessProperties?.customProperties!,
      ),
    },
  };
};

const getDefaultTimezoneType = (customProperties: Property[]) => {
  return (
    customProperties?.find(
      (businessProperty) => businessProperty.propertyName === 'defaultTimezone',
    )?.value || TimezoneType.BUSINESS
  );
};
